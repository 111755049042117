import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppApiService } from 'src/app/app-api.service';
import { AppNotificationService } from 'src/app/app-notification.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PersonService extends AppApiService {
  public loading: boolean = false;

  public url: string | null = 'crm/people';

  constructor(
    protected http: HttpClient,
    protected notificationService: AppNotificationService
  ) {
    super(http, notificationService);
  }

  public async canDuplicatePerson(person: any): Promise<boolean> {
    if (person.primary_email === '') {
      this.notificationService.showNotification('Primary email is required', 'warning');
      return false;
    };
    if (!person.title_id) {
      this.notificationService.showNotification('Title is required', 'error');
      return false;
    }

    if (!person.gender_id) {
      this.notificationService.showNotification('Gender is required', 'error');
      return false;
    }

    if ((<any>await this.get({
      filter: {
        logic: 'and', filters: [
          { field: 'primary_email', operator: 'eq', value: person.primary_email }
        ]
      }
    })).data.length) {
      this.notificationService.showNotification('Primary email already taken', 'warning');
      return false;
    }

    return true;
  }

  public changePrimaryMail(personId : number, newEmail : string, sendInviteMail : boolean = false){
    let url = `${environment.apiUrl}/${this.url}/${personId}/changePrimaryEmail`;
    let body = { newEmail, sendInviteMail };
    return this.http.request("post",url,{body});
  }

  public async canCreatePerson(person: any, isAssistant: boolean = false): Promise<boolean> {
    if (person.primary_email === '') {
      this.notificationService.showNotification('Primary email is required', 'warning');
      return false;
    }

    if (!this.isValidEmail(person.primary_email)) {
      this.notificationService.showNotification('Primary email isn\'t valid', 'warning');
      return false;
    }

    if (!person.title_id) {
      this.notificationService.showNotification('Title is required', 'error');
      return false;
    }

    if (!isAssistant && !person.gender_id) {
      this.notificationService.showNotification('Gender is required', 'error');
      return false;
    }

    if ((<any>await this.get({
      filter: {
        logic: 'and', filters: [
          { field: 'lastname', operator: 'eq', value: person.lastname },
          { field: 'firstname', operator: 'eq', value: person.firstname }
        ]
      }
    })).data.length) {
      this.notificationService.showNotification('Lastname already taken', 'warning');
      return false;
    }

    if ((<any>await this.get({
      filter: {
        logic: 'and', filters: [
          { field: 'primary_email', operator: 'eq', value: person.primary_email }
        ]
      }
    })).data.length) {
      this.notificationService.showNotification('Primary email already taken', 'warning');
      return false;
    }

    return true;
  }

  public isValidEmail(string: string): boolean {
    return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(string);
  }
}
