import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataSourceRequestState, State } from '@progress/kendo-data-query';
import { BehaviorSubject, catchError, EMPTY, from, lastValueFrom, Observable, tap, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AppNotificationService } from './app-notification.service';
import mime from 'mime';


@Injectable({
  providedIn: 'root'
})

export class AppApiService extends BehaviorSubject<any | null> {
  public loading: boolean = false;
  public url: string | null = '';

  constructor(
    protected http: HttpClient,
    protected notificationService?: AppNotificationService
  ) {
    super(null);
  }

  export(state?: State): Observable<HttpResponse<Blob>> {
    return this.downloadFile(this.url + '/export',state);
  }


  all(state?: State): Promise<{ data: any[], sql: string, total: number } | undefined> {
    return this.get(state);
  }

  find(id: string | number | undefined): Promise<{ data: any, sql: string, total: number } | undefined> {
    return this.get(null, this.url + '/' + id);
  }

  public createOrUpdate(element: any) {
    if (element.id) {
      return this.update(element);
    }
    else {
      return this.create(element);
    }
  }

  create(element: any): Promise<any> {
    return this.post(element);
  }

  update(element: any): Promise<Object | undefined> {
    return this.put(element);
  }
  public updateAssoc(keys: any, url?: string): Promise<Object | undefined> {
    return lastValueFrom(this.execute('put', null, keys, -1, url ? url : this.url));
  }
  public get(state?: State | undefined | null | any, url?: string, responseType: 'json' | 'text' | 'arraybuffer' | 'blob' = 'json') {
    return lastValueFrom(this.execute('get', state, null, null, url ? url : this.url, responseType));
  }

  public delete(id: string | number | undefined, url?: string) {
    return lastValueFrom(this.execute('delete', null, null, id, url ? url : this.url));
  }

  public deleteAssoc(keys: any, url?: string) {
    return lastValueFrom(this.execute('delete', null, keys, -1, url ? url : this.url));
  }

  public post(element: any, url?: string) {
    return lastValueFrom(this.execute('post', null, element, null, url ? url : this.url));
  }

  public put(element: any) {
    return lastValueFrom(this.execute('put', null, element, element.id));
  }

  public patch(element: any, url?: string) {
    return lastValueFrom(this.execute('patch', null, element, null, url ? url : this.url));
  }

  public downloadFile(uri : string,  state?: DataSourceRequestState | any | null){
    this.loading = true;
    let url = `${environment.backendBaseUrl}${uri}`;
    if (state) {
      url += '?params=' + JSON.stringify(state);
    }
    return this.http.request('GET',url,{ responseType:'blob', observe:'response'})
      .pipe(
        tap(
          {
            next : (fileDownloadResponse: HttpResponse<Blob>)=>{
              this.downloadBlobFromHttpResponse(fileDownloadResponse);
            },
            error: (error: HttpErrorResponse) => {
              this.handleDownloadError(error);
              return EMPTY;
            },
            complete: () => {
              this.loading = false;
            }
          }
        ),
        catchError(_ => EMPTY)
      )
  }

  private downloadBlobFromHttpResponse(response : HttpResponse<Blob>){
    if(!response.body) throw new Error("File content was empty.");
    let blob = response.body;
    let filename = this.extractFileNameFromHeader(response);

    let downloadLink = document.createElement('a');
    downloadLink.href = window.URL.createObjectURL(blob);
    downloadLink.setAttribute('download', filename);
    document.body.appendChild(downloadLink);
    downloadLink.click();
  }

  private extractFileNameFromHeader(response: HttpResponse<Blob>): string{
    const headers = response.headers;
    const contentDisposition = headers.get('content-disposition');
    const defaultFileName = `file.${this.getExtensionFromHeader(headers)}`;

    if (!contentDisposition) {
      return defaultFileName;
    }

    const extractFilenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const fileNameMatch = contentDisposition.match(extractFilenameRegex)?.[1];
    const fileName = fileNameMatch ? fileNameMatch.replace(/['"]/g, '') : defaultFileName;

    return fileName;
  }

  private getExtensionFromHeader(headers : HttpHeaders) : string{
    const defaultExtension = 'txt';
    const responseContentType = headers.get('content-type');
    if(responseContentType == null) return defaultExtension;
    return mime.getExtension(responseContentType) ?? defaultExtension;
  }

  private handleDownloadError(error:HttpErrorResponse){
    const defaultErrorMessage = 'Couldn\'t handle an error when trying to download a file.';
    if(typeof error?.error?.text === 'function'){
      let blobError = error.error as Blob;
      from(blobError.text()).subscribe(
        (value)=>{
          let errorObject = JSON.parse(value);
          this.notificationService!.showNotification(errorObject?.message ??  defaultErrorMessage , AppNotificationService.STYLE_ERROR);
        }
      );
    }else{
      this.notificationService!.showNotification(defaultErrorMessage, AppNotificationService.STYLE_ERROR);
    }
  }

  private execute(verb: string, state?: DataSourceRequestState | null, body?: string | null, id?: string | number | null, url?: string | null, responseType: 'json' | 'text' | 'arraybuffer' | 'blob' = 'json'): Observable<any> {
    this.loading = true;
    const idPart = id ? ('/' + id) : '';
    url = url ? url : this.url;
    url = `${environment.backendBaseUrl}${url}${idPart}`;
    if (state && url.includes('?list')) {
      url += '&params=' + JSON.stringify(state);
    }
    else if (state) {
      url += '?params=' + JSON.stringify(state);
    }

    return this.http.request(verb, url, { body: body, responseType: responseType }).pipe(
      tap({
        next: (r: any) => {
          this.loading = false;
        }, error: (e => {
          let message = typeof e.error === 'string' ? e.error : (e.error?.message ?? e.error?.data ?? e.message);
          this.notificationService!.showNotification(message, AppNotificationService.STYLE_ERROR);
          this.loading = false;

        }),
        complete: () => {
          this.loading = false;
        }
      })
    );

  }
}
