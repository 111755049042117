import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppNotificationService } from 'src/app/app-notification.service';
import { ModalConfirmComponent } from 'src/app/ui/modal-confirm/modal-confirm.component';
import { PageComponent } from 'src/app/ui/page/page.component';
import { CommitteesMembersService } from '../../committees/committees-members.service';
import { PersonService } from '../person.service';
import { TranslateService } from '@ngx-translate/core';
import { AadService } from 'src/app/core/aad/aad.service';
import { PersonsAddressesService } from '../persons-addresses.service';
import { TenuresService } from '../../companies/tenures/tenures.service';
import { CompaniesService } from '../../companies/companies.service';
import { CompanyRolesService } from '../../companies/company-roles/company-roles.service';
import { CitiesService } from '../../addresses/cities.service';
import { AddressesService } from '../../addresses/addresses.service';
import { PersonsRelationsService } from '../persons-relations.service';
import { CompanyTypesService } from '../../companies/company_types.service';
import { CompanyRoleTypesService } from '../../companies/company-roles/company_role_types.service';
import { AuthenticationService } from 'src/app/auth/authentication.service';
import { MembersService } from '../../members/members.service';
import { BankService } from '../../members/bank.service';
import { CommitteesService } from "../../committees/committees.service";
import { ModalComponent } from 'src/app/ui/modal/modal.component';
import { KendoComponentHelper } from 'src/app/ui/kendo-component-helper/kendo-component-helper';
import { forkJoin } from 'rxjs';
import { CommitteesRolesService } from '../../committees/committees-roles.service';
import { Committee } from '../../committees/committee.modele';
import { Person } from '../person';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AppGridComponent } from 'src/app/ui/grid/grid.component';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { AssistantsService } from '../../assistants/assistants.service';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { Member } from '../../members/member';
import { BreadcrumbEvents } from 'src/app/ui/breadcrumbs/breadcrumb-events';
import { ChangePersonPrimaryEmailModalComponent } from '../change-person-primary-email-modal/change-person-primary-email-modal.component';

@Component({
  selector: 'app-person-details',
  templateUrl: './person-details.component.html',
  styleUrls: ['./person-details.component.scss'],
})
export class PersonDetailsComponent extends PageComponent {
  public element: any = {};
  public element_id: string | number | undefined;

  public member?: any;
  public isAssistant: boolean = false;

  @ViewChild('deleteModal') deleteModal: ModalConfirmComponent = new ModalConfirmComponent();
  @ViewChild('duplicateEmail') duplicateEmail: ModalComponent = new ModalComponent();
  @ViewChild('changePrimaryMailModal') changePrimaryMailModal! : ChangePersonPrimaryEmailModalComponent;

  public duplicateElement: any = {};


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private notificationService: AppNotificationService,
    public committeesMembersService: CommitteesMembersService,
    private personService: PersonService,
    private addressesService: AddressesService,
    private translateService: TranslateService,
    private personsAddressesService: PersonsAddressesService,
    private personsRelationsService: PersonsRelationsService,
    private tenuresService: TenuresService,
    private companiesService: CompaniesService,
    private companyRolesService: CompanyRolesService,
    private citiesService: CitiesService,
    private aadService: AadService,
    private companyTypesService: CompanyTypesService,
    private companyRoleTypesService: CompanyRoleTypesService,
    private authenticationService: AuthenticationService,
    private membersService: MembersService,
    private bankService: BankService,
    private committeesService: CommitteesService,
    private committeesRolesService: CommitteesRolesService,
    private ngxUiLoaderService: NgxUiLoaderService,
    private assistantService: AssistantsService,

  ) {
    super();
    this.route.data.subscribe(({ element }) => {
      this.element = element?.data ?? {};
      if (this.element.hasOwnProperty('person')) {
        this.member = Object.assign({}, this.element);
        this.element = Object.assign({}, this.element.person);
      };

      this.breadcrumbsService.parser = (segment: string) => {
        return {
          text: this.getTextSegment(segment),
          click: (e: any) => { this.router.navigateByUrl('admin/' + segment) }
        }
      };

    });
  }
  ngAfterViewInit(): void {
    super.ngAfterViewInit();
    this.updateAvailableMembers();
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.element_id = this.element_id ?? this.route.snapshot.params.id;
    this.notificationService.toggleLoader();

    if (this.element_id != 'new' && !this.element) {
      this.notificationService.showNotification("Record Not Found", 'error');
      this.notificationService.toggleLoader();
      this.router.navigateByUrl('admin/members');
      return;
    }

    this.addAction({ code: 'save', text: this.element_id === 'new' ? 'Create' : 'Save', icon: 'save', click: () => this.onSaveButtonClicked(), btnStyle: 'outline-primary' });

    if (this.element?.id && !this.element.deffered) {
      // this.addAction({ code: 'duplicate', text: 'Duplicate', icon: 'copy', click: () => this.onDuplicateButtonClicked(), btnStyle: 'outline-primary' });
      this.addAction({ code: 'changeMail', text: 'Change email', icon: 'copy', click: () => this.changePrimaryMailModal.open(), btnStyle: 'outline-primary' });
      if (this.element.isMember) {
        this.addAction({
          code: 'changeToPerson',
          text: 'Exclure',
          icon: 'user-slash',
          click: () => this.onChangeToPersonClicked(),
          btnStyle: 'outline',
          atEnd: true
        });
      } else {
        this.addAction({
          code: 'changeToMember',
          text: 'Invite',
          icon: 'user-plus',
          click: () => this.onChangeToMemberClicked(),
          btnStyle: 'outline',
          atEnd: true
        });
      }

      this.addAction({
        code: 'delete',
        text: 'Delete',
        icon: 'trash',
        click: () => this.onDeleteButtonClicked(),
        btnStyle: 'outline',
        atEnd: true
      });
    }

    if (!this.element.id) {
      this.element = {
        ...this.element,
        firstname: '',
        lastname: '',
        primary_email: '',
        secondary_email: '',
        pro_phone: '',
        private_phone: '',
        private_gsm: '',
      };
    }

    this.loadCommitteesGrid();
    this.notificationService.toggleLoader();
  }

  // Element
  // -------
  public reloadElement(): void {
    this.personService.find(this.element.id).then((person: any) => {
      this.element = person.data;
      this.updateAvailableMembers();
      this.ngOnInit();
    })
  }

  public async onSaveButtonClicked(): Promise<void> {
    if (!this.element.id) {
      if (!await this.personService.canCreatePerson(this.element)) return;

      await this.savePersonAutoCreate(this.element);

      const isMember = this.element.isMember;
      delete this.element.isMember;

      if (isMember) {
        this.element = {
          ...this.element,
          newsletter_revue_wallonie: +(true),
          newsletter_revue_wallonie_count: 1,
          newsletter_externes: +(true),
          invite_for_cese_activities: +(true),
          deffered: true
        }
      }

      this.element = (<any>await this.personService.create(this.element)).data;

      if (isMember) {
        this.member = (<any>await this.membersService.create({
          sendLetterToPrivate: +(false),
          noRefund: +(false),
          person_id: this.element.id
        })).data;

        await this.bankService.create({ ...{ iban_priv: null, iban_pro: null, BCE: null }, member_id: this.member.id });

        try {
          await this.aadService.addMember(this.element);
          await this.aadService.updateMember(this.element);
        } catch (e) { }
      }

      this.element_id = this.element.id;

      this.router.navigateByUrl(`admin/${isMember ? `members/${this.member.id}` : `communications/${this.element.id}`}`);
    } else {
      await this.savePersonAutoCreate(this.element);
      await this.saveAddresses(this.element);

      await this.personService.update(this.element);

      if (this.element.isMember) {
        await this.bankService.update(this.member.bank_datas);
        await this.membersService.update(this.member);
        this.aadService.updateMember(this.element);
      }

      this.notificationService.showNotification('Record Updated', 'success');
    }

    this.ngOnInit();
  }

  public onDuplicateButtonClicked() {
    this.duplicateElement = JSON.parse(JSON.stringify(this.element));
    this.duplicateEmail.toggle();
  }

  public async onConfirmDuplicateButtonClicked(): Promise<void> {
    this.ngxUiLoaderService.start();

    const isMember = this.duplicateElement.isMember;

    this.duplicateElement.id = null;
    this.duplicateElement.microsoft_id = null;
    this.duplicateElement.deffered = isMember ? true : false;

    if (!await this.personService.canDuplicatePerson(this.duplicateElement)) {
      this.ngxUiLoaderService.stop();
      return;
    };

    await this.savePersonAutoCreate(this.duplicateElement);

    delete this.duplicateElement.isMember;

    // Create duplicated Person
    // ------------------------
    this.duplicateElement = (<any>await this.personService.create(this.duplicateElement)).data;

    // Addresses
    // ---------
    for (const link of this.element.persons_addresses) {
      await this.personsAddressesService.create({
        person_id: this.duplicateElement.id,
        address_id: link.address_id,
        address_type_id: link.address_type_id
      });
    }

    // Relations
    // ---------

    // assistant relation
    for (const member of (await this.assistantService.getMembers(this.element.id))?.data) {
      const relation = member.person.persons_relations.find((relation: any) => relation.relation_id === this.element.id);
      if (!relation) continue;

      await this.personsRelationsService.updateAssoc({
        'old': {
          person_id: relation.person_id,
          relation_id: relation.relation_id,
          relation_type_id: relation.relation_type_id,
        },
        'new': {
          person_id: relation.person_id,
          relation_id: this.duplicateElement.id,
          relation_type_id: relation.relation_type_id,
        }
      });
    }

    for (const link of this.element.persons_relations) {
      await this.personsRelationsService.updateAssoc({
        'old': {
          person_id: link.person_id,
          relation_id: link.relation_id,
          relation_type_id: link.relation_type_id,
        },
        'new': {
          person_id: this.duplicateElement.id,
          relation_id: link.relation_id,
          relation_type_id: link.relation_type_id,
        }
      });
    }

    let duplicatedMember = null;

    if (isMember) {
      let duplicatedMemberData = JSON.parse(JSON.stringify(this.member));
      duplicatedMemberData.id = null;
      duplicatedMemberData.person_id = this.duplicateElement.id;

      // Member
      // ------
      duplicatedMember = (<any>await this.membersService.create(duplicatedMemberData)).data;

      // Bank informations
      // -----------------
      await this.bankService.create({ ...duplicatedMemberData.bank_datas, member_id: duplicatedMember.id });

      // AAD user
      // --------
      await this.membersService.delete(this.member.id);
      await this.aadService.deleteMember(this.element);

      await this.aadService.addMember(this.duplicateElement);
      await this.aadService.updateMember(this.duplicateElement);

      this.duplicateElement = (<any>await this.personService.find(this.duplicateElement.id)).data; // Required to fetch microsoft_id

      // Committees
      // ----------
      for (const link of duplicatedMemberData.committees_members) {
        await this.committeesMembersService.create({
          member_id: duplicatedMember.id,
          committee_id: link.committee_id,
          committee_role_id: link.committee_role.id,
        });
      };

      this.member = duplicatedMember;
    }

    await this.personService.delete(this.element.id);
    this.element = this.duplicateElement;

    this.router.navigateByUrl(`admin/${isMember ? `members/${duplicatedMember.id}` : `communications/${this.duplicateElement.id}`}`);
    this.ngxUiLoaderService.stop();
    this.duplicateEmail.toggle();
    this.reloadElement();
  }

  public async savePersonAutoCreate(element: any): Promise<void> {
    if (element.person_tenure?.id)
      element.tenure_id = element.person_tenure.id === "-1" ? (<any>await this.tenuresService.create(element.person_tenure)).data.id : element.person_tenure.id;
    if (element.person_company?.id)
      element.company_id = element.person_company.id === "-1" ? (<any>await this.companiesService.create(element.person_company)).data.id : element.person_company.id;
    if (element.person_company_type?.id)
      element.company_type_id = element.person_company_type.id === "-1" ? (<any>await this.companyTypesService.create(element.person_company_type)).data.id : element.person_company_type.id;
    if (element.person_company_role?.id)
      element.company_role_id = element.person_company_role.id === "-1" ? (<any>await this.companyRolesService.create(element.person_company_role)).data.id : element.person_company_role.id;
    if (element.person_company_role_type?.id)
      element.company_role_type_id = element.person_company_role_type.id === "-1" ? (<any>await this.companyRoleTypesService.create(element.person_company_role_type)).data.id : element.person_company_role_type.id;
  }

  public async saveAddresses(person: Person): Promise<void> {
    for (const link of person.persons_addresses) {
      // if (link.address.city?.id === -1) {
      //   link.address.city_id = (<any>await this.citiesService.create(link.address.city)).data.id;
      // } else {
      //   await this.citiesService.update(link.address.city);
      // }

      this.addressesService.update(link.address);
    }
  }

  public onDeleteButtonClicked(): void {
    this.deleteModal.toggle();
  }

  public async confirmDelete(): Promise<void> {
    if (this.element.isMember) {
      await this.membersService.delete(this.member.id);
    }
    try {
      await this.aadService.deleteMember(this.element); // Changed because of #5540
    } catch (error) { }
    await this.personService.delete(this.element.id);
    await this.aadService.deleteMember(this.element); // Changed because of #5540

    this.notificationService.showNotification('Record Deleted', 'success');
    this.deleteModal.toggle();

    if (this.element.isMember) {
      this.router.navigateByUrl('admin/members');
    } else {
      this.router.navigateByUrl('admin/communications');
    }
  }

  // // Contact
  // // -------
  // public onContactCreate(contact: any): void {
  //   this.personsContactsService.create({
  //     person_id: this.element.id,
  //     contact_id: contact.id
  //   }).then(() => {
  //     this.notificationService.showNotification('Record Created', 'success');
  //     this.reloadElement();
  //   })
  // }

  // public onContactUpdate(): void {
  //   this.notificationService.showNotification('Record Updated', 'success');
  //   this.reloadElement();
  // }

  // public onContactDelete(contact: any): void {
  //   this.personsContactsService.deleteAssoc({
  //     person_id: this.element.id,
  //     contact_id: contact.id
  //   }).then(() => {
  //     this.contactService.delete(contact.id).then((r: any) => {
  //       this.notificationService.showNotification('Record Deleted', 'success');
  //       this.reloadElement();
  //     })
  //   })
  // }

  // Address
  // -------
  public onAddressCreate(address: any, person?: Person): void {
    if (!person || person == null) {
      person = this.element;
    }
    if (!person) return;

    this.personsAddressesService.create({
      person_id: person.id,
      address_id: address.address_id,
      address_type_id: address.address_type_id
    }).then(() => {
      this.notificationService.showNotification('Record Created', 'success');
      this.reloadElement();
    });
  }

  public onAddressDelete(address: any): void {
    this.personsAddressesService.deleteAssoc({
      person_id: this.element.id,
      address_id: address.address_id,
      address_type_id: address.address_type.address_type_id,
    }).then(() => {
      this.notificationService.showNotification('Record Deleted', 'success');
      this.reloadElement();
    });
  }

  // Relation
  // --------
  public async onRelationCreate(relation: any, person?: Person): Promise<void> {
    if (!person || person == null) {
      person = this.element;
    }
    if (!person) return;

    if (person.id === relation.id) {
      return this.notificationService.showNotification('Impossible d\ajouter une relation vers soi-même', 'error');
    }

    const relation_type_id = relation.relation_type.id;

    if (!relation.id) {
      relation = (<any>await this.personService.create({
        ...relation,
        title_id: relation.title.id
      })).data;
    }

    await this.personsRelationsService.create({
      person_id: person.id,
      relation_id: relation.id,
      relation_type_id: relation_type_id,
    });

    this.notificationService.showNotification('Record Created', 'success');
    this.reloadElement();
  }

  public onRelationDelete(): void {
    this.notificationService.showNotification('Record Deleted', 'success');
    this.reloadElement();
  }

  // Breadcrumb
  // ----------
  public getTextSegment(segment: string) {
    // If segment is a number value
    // ----------------------------
    if (!isNaN(+segment)) {
      return this.element.fullname;
    }

    // If segment is a string Value
    // ----------------------------
    switch (+this.element.contact_label_id) {
      case 1:
        return this.translateService.instant("Assistant");
      default:
        return segment.charAt(0).toUpperCase() + segment.slice(1)
    }
  }

  // AAD ACTION
  // ----------
  @ViewChild('confirmChangeToMemberModal') public confirmChangeToMemberModal: ModalConfirmComponent = new ModalConfirmComponent();
  @ViewChild('confirmChangeToPersonModal') public confirmChangeToPersonModal: ModalConfirmComponent = new ModalConfirmComponent();

  private onChangeToMemberClicked(): void {
    this.confirmChangeToMemberModal.toggle();
  }

  public async onConfirmToMemberClicked(): Promise<void> {
    this.member = (<any>await this.membersService.create({
      sendLetterToPrivate: +(false),
      noRefund: +(false),
      person_id: this.element.id
    })).data;

    this.element = {
      ...this.element,
      newsletter_revue_wallonie: +(true),
      newsletter_revue_wallonie_count: 1,
      newsletter_externes: +(true),
      invite_for_cese_activities: +(true),
      deffered: true
    }

    await this.personService.update(this.element);

    await this.bankService.create({ ...{ iban_priv: null, iban_pro: null, BCE: null }, member_id: this.member.id });

    this.confirmChangeToMemberModal.toggle();

    this.notificationService.showNotification('New member invited', 'success');

    await this.aadService.addMember(this.element);
    this.aadService.updateMember(this.element);

    this.router.navigateByUrl(`admin/members/${this.member.id}`);
  }

  private onChangeToPersonClicked(): void {
    this.confirmChangeToPersonModal.toggle();
  }

  public async onConfirmToPersonClicked(): Promise<void> {
    await this.bankService.delete(this.member.bank_datas.id);
    await this.membersService.delete(this.member.id);
    this.element.microsoft_id = null;
    await this.personService.update(this.element);

    this.confirmChangeToPersonModal.toggle();

    this.aadService.deleteMember(this.element);

    this.notificationService.showNotification('Member excluded', 'success');

    this.router.navigateByUrl(`admin/communications/${this.element.id}`);
  }

  // Member
  // ------
  public hasOneRole(roles: string[]) {
    return this.authenticationService.hasOneRole(roles);
  }

  // Committee members
  // -----------------
  public sections: Committee[] = [];
  public committeesHelper: KendoComponentHelper = new KendoComponentHelper();
  public sectionsHelper: KendoComponentHelper = new KendoComponentHelper();
  public committeeRolesHelper: KendoComponentHelper = new KendoComponentHelper();
  @ViewChild('deleteCommitteeMemberModal') public deleteCommitteeMemberModal!: ModalComponent;
  @ViewChild('editCommitteeModal') public editCommitteeModal!: ModalComponent;
  @ViewChild('assignModal') public assignModal!: ModalComponent;

  public committeesActions: any[] = [
    {
      code: 'add-commitee',
      text: 'Add an assignation',
      icon: 'address-book',
      click: () => {
        this.assignModal.data = { 'assignment_date': new Date() };
        this.assignModal.toggle();
      },
    }
  ];

  public onInstanceChange(instanceId: number) {
    this.assignModal.data.section = null;
    this.sectionsHelper.datas = this.sectionsHelper.filteredDatas = this.sections.filter((section: Committee) => section.parent_id === instanceId);
  }

  public onConfirmAssignationButtonClicked() {
    const item = this.assignModal.data;

    this.committeesMembersService.create({
      member_id: this.member.id,
      committee_id: item.section?.id || item.instance.id,
      committee_role_id: item.committee_role.id,
      assignment_date: this.committeesMemberFormatDate(item.assignment_date)
    }).then(async (r) => {
      this.loadCommitteesGrid();
      this.notificationService.showNotification("Record Created", 'success');

      const committee = item.section?.id ? item.section : item.instance
      this.aadService.addMemberToGroup(committee, this.member.person);
      const addedToGroup = await this.aadService.isMember(committee, this.member.person);
      if (!addedToGroup) {
        this.element.deffered = true;
        await this.personService.update(this.element);
      }
    }).finally(() => {
      this.assignModal.toggle();
    });
  }

  private committeesMemberFormatDate(date: Date) {
    return date.getFullYear() + '-' +
      (date.getMonth() + 1).toString().padStart(2, '0') + '-' +
      date.getDate().toString().padStart(2, '0') + ' ' +
      date.getHours().toString().padStart(2, '0') + ':' +
      date.getMinutes().toString().padStart(2, '0') + ':' +
      date.getSeconds().toString().padStart(2, '0');
  }


  public loading: boolean = true;
  public data: any = [];
  public columns: any[] = [
    { field: 'instance.displayName.fr', title: 'Instance' },
    { field: 'section.displayName.fr', title: 'Section' },
    { field: 'committee_role.displayName.fr', title: 'Role' }
  ];

  public async loadCommitteesGrid(): Promise<void> {
    if (!this.member) return;

    forkJoin({
      committees: this.committeesService.get({ filter: { logic: 'and', filters: [{ field: 'type', operator: 'eq', value: 'instance' }] }, sort: [{ dir: "asc", field: "displayName.fr" }] }),
      committeeRoles: this.committeesRolesService.get(),
      sections: this.committeesService.get({ filter: { logic: 'and', filters: [{ field: 'type', operator: 'eq', value: 'section' }] }, sort: [{ dir: "asc", field: "displayName.fr" }] })
    }).subscribe((responses: any) => {
      // Committee
      let committees = responses.committees.data;
      if (this.member.committees_members) {
        committees = committees.filter(
          (e: any) =>
            !this.member
              .committees_members!.map((e: any) => e.committee_id)
              .includes(e.id)
        );
      }

      this.committeesHelper.datas = this.committeesHelper.filteredDatas = committees;
      this.committeeRolesHelper.datas = this.committeeRolesHelper.filteredDatas = responses.committeeRoles.data;

      // Section
      let sections = responses.sections.data;
      if (this.member.committees_members) {
        sections = sections.filter(
          (e: any) =>
            !this.member
              .committees_members!.map((e: any) => e.committee_id)
              .includes(e.id)
        );
      }

      this.sections = this.sectionsHelper.datas = this.sectionsHelper.filteredDatas = sections;
      this.onInstanceChange(0);
    });

    // Self
    this.loading = true;
    this.data = [];
    const committeeMembers: any = (<any>await this.committeesMembersService.all({
      filter: {
        logic: 'and',
        filters: [
          {
            field: 'member_id',
            operator: 'eq',
            value: this.member.id,
          }
        ],
      }
    })).data;

    const gridCommitteeMembers: any[] = [];

    for (const committeeMember of committeeMembers) {
      const committee = committeeMember.committee;
      let instance = null;
      let section = null;

      if (committee.type === 'instance') {
        instance = committee;
      } else {
        instance = (<any>await this.committeesService.find(committee.parent_id)).data;
        section = committee;
      }

      gridCommitteeMembers.push({
        ...committeeMember,
        instance,
        section
      });
    }

    this.data = gridCommitteeMembers;
    this.loading = false;
  }

  private initialAssignmentDate: string = '';
  public onEditButtonClicked(item: any) {
    this.editCommitteeModal.data = item;
    this.editCommitteeModal.data.assignment_date = item.assignment_date === '0000-00-00 00:00:00' ? null : new Date(item.assignment_date);
    this.initialAssignmentDate = this.editCommitteeModal.data.assignment_date;
    this.editCommitteeModal.toggle();
  }

  public onConfirmEditButtonClicked(): void {
    const item = this.editCommitteeModal.data;

    this.committeesMembersService.updateAssoc({
      'old': {
        member_id: item.member_id,
        committee_id: item.committee_id,
        committee_role_id: item.committee_role_id
      },
      'new': {
        member_id: item.member_id,
        committee_id: item.committee_id,
        committee_role_id: item.committee_role.id,
        assignment_date: item.assignment_date === null ? null : this.committeesMemberFormatDate(item.assignment_date)
      }
    }).then(r => {
      this.loadCommitteesGrid();
      this.notificationService.showNotification("Record Updated", 'success');

    }).finally(() => {
      this.editCommitteeModal.toggle();
    });
  }

  public onCommunitteeMemberDeleteButtonClicked(item: any): void {
    this.deleteCommitteeMemberModal.data = item;
    this.deleteCommitteeMemberModal.toggle();
  }

  public onConfirmDeleteCommitteeMember(): void {
    const link = this.deleteCommitteeMemberModal.data;

    this.committeesMembersService.deleteAssoc({ member_id: link.member_id, committee_id: link.committee_id, committee_role_id: link.committee_role_id }).then(async (r: any) => {
      this.notificationService.showNotification("Record Deleted", 'success');

      this.loadCommitteesGrid();

      this.aadService.removeMemberFromGroup(link.committee, this.element);

    }).finally(() => {
      this.deleteCommitteeMemberModal.toggle();
    });
  }


  // Members
  // -------
  @ViewChild('membersGrid') membersGrid?: AppGridComponent;
  public membersHelper: KendoComponentHelper = new KendoComponentHelper();

  public membersFilters!: CompositeFilterDescriptor;
  public membersColumns: any[] = [
    {
      field: "person.person_gender.displayName", title: "Gender", joins: [
        { sequence: 1, tablename: "members", foreignKey: "committees_members.member_id" },
        { sequence: 2, tablename: "people", foreignKey: "members.person_id" },
        { sequence: 2, tablename: "person_genders", foreignKey: "people.gender_id" }
      ]
    },
    {
      field: "person.lastname", title: "Lastname", joins: [
        { sequence: 1, tablename: "members", foreignKey: "committees_members.member_id" },
        { sequence: 2, tablename: "people", foreignKey: "members.person_id" }
      ]
    },
    {
      field: "person.firstname", title: "Firstname", joins: [
        { sequence: 1, tablename: "members", foreignKey: "committees_members.member_id" },
        { sequence: 2, tablename: "people", foreignKey: "members.person_id" }
      ]
    },
    {
      field: 'person.person_tenure.displayName.fr', title: 'Tenure', joins: [
        { sequence: 1, tablename: "members", foreignKey: "committees_members.member_id" },
        { sequence: 2, tablename: "people", foreignKey: "members.person_id" },
        { sequence: 4, tablename: "person_tenures", foreignKey: "people.tenure_id" }
      ]
    },
  ];

  public membersSorts = [
    {
      dir: 'asc', field: 'person.lastname', joins: [
        { sequence: 1, tablename: "members", foreignKey: "committees_members.member_id" },
        { sequence: 2, tablename: "people", foreignKey: "members.person_id" }
      ]
    }
  ]

  public onMembersDoubleClick(member: Member) {
    this.router.navigateByUrl('admin/members/' + member.id);
  }

  private async updateAvailableMembers() {
    const relations: GridDataResult = <GridDataResult>await this.assistantService.getMembers(this.element.id);
    this.isAssistant = relations.total > 0;

    this.populateRelations(relations);
  }

  private populateRelations(relations: GridDataResult) {
    if (!this.membersGrid) {
      setTimeout(() => {
        this.populateRelations(relations);
      }, 500);
      return;
    }

    this.membersGrid.dataResult = relations;
  }

}
